import React from 'react'
import { InputText } from 'primereact/inputtext'
import { FloatLabel } from 'primereact/floatlabel'

const FloatLabelTextInput = ({ label, value, onChange }) => (
  <FloatLabel style={{ width: '50%' }}>
    <InputText value={value} onChange={onChange} style={{ width: '100%' }} />
    <label>{label}</label>
  </FloatLabel>
)

export default FloatLabelTextInput
