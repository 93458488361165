import React, { useState } from 'react'
import { Button, Card, Flex, Input, Spinner } from '../../Components'
import { InputText } from 'primereact/inputtext'
import { FloatLabel } from 'primereact/floatlabel'
import { Password } from 'primereact/password'
import { useAuth } from '../../Provider/AuthProvider'
import siapLogo from '../../Resources/LogoSiap.png'

function Login () {
  const { onLogin } = useAuth()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)

  const onClickLogin = async () => {
    setWaiting(true)
    await onLogin({ username, password })
    setWaiting(false)
    console.log(username)
  }

  return (
    <Flex fw fh>
      <Card style={{ width: '550px', height: '550px', padding: 50, borderRadius: 28, margin: '0 auto' }}>
        <Flex fw fh>
          <img src={siapLogo} style={{ width: 400 }} />
          <Flex fw fh style={{ gap: '1.5em', marginTop: '36px', marginBottom: '24px' }}>
            <FloatLabel>
              <InputText
                id='username'
                name='username'
                value={username}
                onChange={(e) => { console.log(e); setUsername(e.target.value) }}
                style={{ width: 400 }}
              />
              <label htmlFor='username'>Username</label>
            </FloatLabel>
            <FloatLabel>
              <Input password placeholder='Password' id='password' value={password} onChange={setPassword} style={{ border: '1px solid #a6a6a6', borderRadius: 3, width: 400 }} onEnter={onClickLogin} />
              <label htmlFor='password'>Password</label>
            </FloatLabel>
          </Flex>
          {waiting
            ? <Spinner />
            : <Button primary label='Accedi' icon='cloud-up' onClick={onClickLogin} style={{ marginBottom: 10, marginTop: 20 }} />}
        </Flex>
      </Card>
    </Flex>
  )
}

export default Login
