import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'
import { Text } from './Text'
import { Flex } from './Flex'
import FadeIn from './FadeIn'

const transition = { default: { duration: 0.4 } }
const CardContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { height: 'auto', opacity: 1, marginTop: 10, transition },
      closed: { height: 0, opacity: 0, marginTop: 0, transition }
    }}
  >
    {children}
  </motion.div>
)

const ChevronContainer = ({ children, ...props }) => (
  <motion.div
    {...props} variants={{
      open: { rotate: 0, transition },
      closed: { rotate: 180, transition }
    }}
  >
    {children}
  </motion.div>
)

export const Card = ({ fadeIn, collapsable, title, titleCollapsed, titleStyle, collapsed, children, style, customHeader, onExpand, onClick = () => { } }) => {
  const [state, setState] = useState('closed')
  const [hovered, setHovered] = useState(false)
  const isCollapsed = state === 'closed'

  const toggleCollapse = (newState) => {
    const applyNewState = newState || (isCollapsed ? 'open' : 'closed')
    setState(applyNewState)
    applyNewState === 'open' && onExpand && onExpand()
  }

  useEffect(() => { collapsable && setState(collapsed ? 'closed' : 'open') }, [collapsable, collapsed])

  const cardStyle = {
    backgroundColor: '#FFFFFF',
    borderRadius: 18,
    padding: 20,
    backdropFilter: 'blur(4px) saturate(180%)',
    WebkitBackdropFilter: 'blur(4px) saturate(180%)',
    color: '#3C4043',
    ...(collapsable ? { position: 'relative' } : {}),
    ...(collapsable && isCollapsed && !customHeader ? { cursor: 'pointer' } : {}),
    ...style,
    width: undefined
  }

  return (
    <FadeIn enabled={fadeIn} style={{ ...(style ? { width: style.width, height: style.height } : {}) }}>
      <div
        style={cardStyle}
        onMouseOver={() => { setHovered(true) }}
        onMouseLeave={() => setHovered(false)}
        {...(isCollapsed && !customHeader ? { onClick: () => toggleCollapse('open') } : {})}
      >
        {!customHeader && !title
          ? null
          : (
            <Flex row js fw>
              <Flex row js>
                {title
                  ? (
                    <Text
                      title
                      bold
                      value={titleCollapsed ? (isCollapsed ? titleCollapsed : title?.toUpperCase()) : title?.toUpperCase()}
                      style={{ ...titleStyle, marginRight: 20 }}
                      size={24}
                    />
                    )
                  : null}
                {customHeader}
              </Flex>
              {collapsable && (
                <ChevronContainer
                  initial='closed'
                  animate={state}
                  style={{ cursor: 'pointer' }}
                  onClick={() => (customHeader || !isCollapsed) && toggleCollapse()}
                >
                  <i className='pi pi-chevron-down' style={{ fontSize: '1.5em' }} />
                </ChevronContainer>
              )}
            </Flex>
            )}
        {!collapsable
          ? (
            <div onClick={onClick}>{children}</div>
            )
          : (
            <CardContainer initial='closed' style={{ overflow: 'hidden' }} animate={state}>
              {children}
            </CardContainer>
            )}
      </div>
    </FadeIn>
  )
}
