import React from 'react'

import { Text } from './Text'

const renderBadge = (badge, badgeSize) => (
  <div
    style={{
      borderRadius: '50%',
      width: badgeSize || 16,
      height: badgeSize || 16,
      backgroundColor: 'rgb(145, 0, 0)',
      position: 'absolute',
      right: -8,
      bottom: -4
    }}
  >
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Text
        size={badgeSize - 4 || 12}
        color='white'
        style={{ fontFamily: 'Lato', padding: 4, paddingTop: 5 }}
        center
        value={badge}
      />
    </div>
  </div>
)

const renderWithImage = ({ image, size, badge, style, onClick, imageStyle, text }) => {
  const badgeNumber = badge === 0 ? null : badge
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} onClick={onClick}>
      <div
        style={{
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 100,
          height: 100,
          backgroundColor: 'white',
          borderRadius: '50px',
          flexDirection: 'column',
          ...style
        }}
      >
        <img
          src={image}
          alt=''
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
            padding: 10,
            ...imageStyle
          }}
        />
        {badgeNumber && renderBadge(badgeNumber)}
      </div>
      {text && <Text style={{ textAlign: 'center', marginTop: 10 }} bold value={text} />}
    </div>
  )
}

const renderIcon = ({
  name,
  size,
  badge,
  style,
  onClick,
  color,
  iconStyle,
  text,
  textStyle,
  badgeSize,
  posed,
  onMouseOver,
  onMouseIn,
  onMouseOut
}) => {
  const iconSize = size || 36

  const iconOverrides = {
    'primeicon pi-power-off': 'pi pi-fw pi-power-off'
  }

  const className = iconOverrides[name] || `icon icon-${name || 'agencies'}`
  const badgeNumber = badge === 0 ? null : badge
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: iconSize,
          height: iconSize,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style
        }}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseEnter={onMouseIn}
        onMouseLeave={onMouseOut}
      >
        <div
          className={className}
          style={{
            fontSize: iconSize,
            ...(color === 'inherit' ? {} : { color: !posed ? color || '#32324e' : '' }),
            ...iconStyle
          }}
        >
          {badgeNumber && renderBadge(badgeNumber, badgeSize)}
        </div>
      </div>
      {text && (
        <Text
          style={{
            ...textStyle,
            // marginLeft: -10,
            marginTop: 10,
            textAlign: 'center'
          }}
          bold
          value={text}
        />
      )}
    </>
  )
}

export const Icon = (props) => {
  if (props.image) return renderWithImage(props)
  return renderIcon(props)
}

export const IconList = () => (['account',
  'add-person',
  'add',
  'agencies',
  'alarm',
  'archive-2',
  'archive',
  'assign',
  'attached',
  'attention-2',
  'attention-circle-2',
  'attention-circle',
  'attention',
  'axis-2',
  'axis',
  'back',
  'beach',
  'beauty',
  'bike',
  'bin',
  'binoculars',
  'blocknote',
  'browser-search',
  'browser',
  'business',
  'calendar-happy',
  'calendar-sad',
  'calendar',
  'camp',
  'camper',
  'car',
  'cart',
  'change',
  'chat-history',
  'chat',
  'chat2',
  'check-round',
  'check',
  'climbing',
  'clock',
  'close',
  'cloud-down',
  'cloud-up',
  'cocktail',
  'coffee',
  'connection',
  'controls-2',
  'controls',
  'copy',
  'cruise',
  'culture',
  'database-2',
  'database-time',
  'database',
  'details-2',
  'details',
  'diagram',
  'doc-2',
  'documents',
  'dog',
  'dollar',
  'down-2',
  'down-round',
  'down',
  'download',
  'drag',
  'edit-2',
  'edit',
  'elements',
  'eliminate-round',
  'emoticons',
  'estimate',
  'experiments',
  'expired-2',
  'expired',
  'expiring',
  'extreme',
  'facebook',
  'family',
  'fax',
  'folder-2',
  'folder',
  'go',
  'google+',
  'gourmet',
  'happy',
  'headphones',
  'history',
  'home',
  'hotel',
  'image',
  'import',
  'info-2',
  'info',
  'instagram',
  'left-2',
  'left-round',
  'left',
  'legal',
  'lens',
  'link',
  'linkedin',
  'love',
  'mail-open',
  'mail',
  'menu',
  'minus',
  'money',
  'monitor',
  'more-love',
  'more-star',
  'motorbike',
  'mountain',
  'new-box-2',
  'new-box',
  'notes',
  'notification',
  'paperwork-2',
  'paperwork',
  'pc',
  'phone-chat',
  'phone',
  'photo',
  'pinterest',
  'plane',
  'play',
  'plus',
  'pointer',
  'print',
  'proceed',
  'questions-2',
  'questions',
  'quote-2',
  'quote',
  'resize',
  'right-2',
  'right-round',
  'right',
  'sad',
  'see',
  'send',
  'settings',
  'share',
  'sheet-2',
  'sheet-new',
  'sheet',
  'shield',
  'silence',
  'sincronize-pc',
  'sincronize-phone',
  'sincronize-siap',
  'site',
  'size',
  'skype',
  'star',
  't-shirt',
  'tablet',
  'teatre',
  'telephone',
  'tent',
  'thumb-down',
  'thumb-up',
  'ticket',
  'tool',
  'train',
  'travel-2',
  'travel',
  'tree',
  'twitter',
  'up-2',
  'up-round',
  'up',
  'volume',
  'world',
  'youtube',
  'zoom-in',
  'zoom-out',
  'accident',
  'xls-2',
  'pdf',
  'pdf-2'
])

const styles = `
.icon-container .icon:hover {
  color: blue !important;
}
`

const styleSheet = document.createElement('style')
styleSheet.type = 'text/css'
styleSheet.innerText = styles
document.head.appendChild(styleSheet)
