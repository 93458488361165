import React from 'react'
import ReactDOM from 'react-dom/client'

// primereact
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import App from './src/App'
// import reportWebVitals from './src/reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
