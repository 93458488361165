import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import fcAuth from '@feathersjs/authentication-client'
import io from 'socket.io-client/dist/socket.io'

// const isDev = !(window._env_ && window._env_.NODE_ENV && window._env_.NODE_ENV === 'production')
const API_BASE_URL = window?._env_?.API_BASE_URL ? window._env_.API_BASE_URL : 'http://localhost:3000'

const FC = {
  socketConfig: {
    timeout: 30000,
    pingInterval: 5000,
    pingTimeout: 20000
  },
  socket: io(API_BASE_URL),
  client: feathers(),
  authenticated: false,
  online: false,
  isDev: true,
  connectionHandler: (event) => () => {
    FC.isDev && console.log(`Socket ${event} to ${API_BASE_URL}`)
    FC.online = event === 'connect'
  }
}

FC.client.configure(socketio(FC.socket, FC.socketConfig))
FC.client.configure(fcAuth({ storage: new fcAuth.MemoryStorage() }))
FC.socket.on('connect', FC.connectionHandler('connect'))
FC.socket.on('disconnect', FC.connectionHandler('disconnect'))

FC.addListeners = () => {
  FC.isDev && console.log('FC Authenticated & Listening for events')
}

FC.stopListeners = () => {
  FC.isDev && console.log('FC Stopped listening for events')
}

FC.login = async (credentials) => {
  try {
    const response = await FC.client.authenticate(credentials)
    FC.authenticated = !!response.accessToken
    FC.authenticated && FC.addListeners()
    return response
  } catch (details) {
    FC.error = details
    return false
  }
}

FC.logout = async () => {
  FC.stopListeners()
  FC.client.logout()
  FC.authenticated = false
}

FC.service = (...params) => FC.client.service(...params)

FC.isReady = () => FC.online && FC.authenticated

export { FC }
