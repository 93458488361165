import React from 'react'

import { InputTextarea } from 'primereact/inputtextarea'
import ReactClipboard from 'react-clipboardjs-copy'
import { Modal } from '../../../Components/Common/Modal'
import { Flex } from '../../../Components/Common/Flex'
import { Button } from '../../../Components/Common/Button'
import { Text } from '../../../Components/Common/Text'
import { Icon } from '../../../Components/Common/Icon'

const SqlModal = ({ isModalVisible, setIsModalVisible, sqlOutput, sqlOutputRef, handleCopy, isTextCopied }) => (
  <Modal
    style={{ width: '700px', height: '600px', borderRadius: 28 }}
    visible={isModalVisible}
    onHide={() => setIsModalVisible(false)}
  >
    <Flex ab row js style={{ gap: '.5em' }}>
      <ReactClipboard action='cut' target='#textToCopy'>
        <Button primary tooltip='Copia' icon='copy' id='copyButton' onClick={handleCopy} />
      </ReactClipboard>
      {isTextCopied && (
        <>
          <Text value='Testo copiato con successo' style={{ color: 'grey' }} />
          <Icon name='check' size={12} color='green' style={{ fontWeight: 900 }} />
        </>
      )}
    </Flex>
    <div style={{
      position: 'relative',
      width: '100%'
    }}
    >
      <InputTextarea id='textToCopy' value={sqlOutput} ref={sqlOutputRef} style={{ width: '100%', maxWidth: '800px', minHeight: '500px', marginTop: '1em' }} />
    </div>
  </Modal>
)

export default SqlModal
