import React from 'react'
import { Card } from '../../../Components/Common/Card'
import { Flex } from '../../../Components/Common/Flex'
import { Text } from '../../../Components/Common/Text'
import { Button } from '../../../Components/Common/Button'

const DownloadCard = ({ downloadLink }) => (
  <Card style={{ width: '40%', minHeight: '130px' }}>
    <Flex as>
      <Text style={{ marginBottom: '1em' }} value='Scarica EXCEL processato' size={18} bold />
      <a id='downloadLink' style={{ textDecoration: 'none' }} href={downloadLink} download='output.xlsx'>
        <Button icon='sheet-new' label='Download Excel' primary />
      </a>
    </Flex>
  </Card>
)

export default DownloadCard
