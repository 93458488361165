import React from 'react'
import { Card } from '../../../Components/Common/Card'
import { Flex } from '../../../Components/Common/Flex'
import { Text } from '../../../Components/Common/Text'
import { Button } from '../../../Components/Common/Button'
import FloatLabelTextInput from '../../../Components/Common/FloatLabelTextInput'
import SwitchInput from './SwitchInput'
import { Spinner } from '../../../Components/Common/Spinner'

const ParametersForm = ({
  parameters,
  setParameters,
  isProcessing,
  handleProcess
}) => {
  const { checked, nomeColonnaImporto, nomeColonnaImporto2, nomeColonnaData, giorniTolleranza, colonneData } = parameters

  return (
    <Card style={{ width: '100%', cursor: 'pointer' }}>
      <Flex fw js as style={{ width: '50%', marginTop: '1em', marginBottom: '1em' }}>
        <Text title bold size={20} value='Parametri' />
        <SwitchInput label='Attiva Seconda Colonna Importo' checked={checked} onChange={(e) => setParameters({ ...parameters, checked: e.value })} />
      </Flex>
      <Flex js fw row style={{ gap: '1em', marginBottom: '1.5em', marginTop: '1.5em' }}>
        <FloatLabelTextInput label='Titolo Colonna Importo A Debito' value={nomeColonnaImporto} onChange={(e) => setParameters({ ...parameters, nomeColonnaImporto: e.target.value })} />
        {checked && (
          <FloatLabelTextInput label='Titolo Colonna Importo A Credito' value={nomeColonnaImporto2} onChange={(e) => setParameters({ ...parameters, nomeColonnaImporto2: e.target.value })} />
        )}
        <FloatLabelTextInput label='Titolo Colonna Data' value={nomeColonnaData} onChange={(e) => setParameters({ ...parameters, nomeColonnaData: e.target.value })} />
      </Flex>
      <Flex js fw row style={{ gap: '1em', paddingBottom: '1em' }}>
        <FloatLabelTextInput label='Giorni Tolleranza' value={giorniTolleranza} onChange={(e) => setParameters({ ...parameters, giorniTolleranza: e.target.value })} />
        <FloatLabelTextInput label='Colonne Data (separate da virgola)' value={colonneData} onChange={(e) => setParameters({ ...parameters, colonneData: e.target.value })} />
        {!isProcessing
          ? (
            <Button
              primary
              label='Processa'
              icon='xls-2'
              id='processButton'
              onClick={handleProcess}
              style={{ width: '20%' }}
            />
            )
          : (
            <Spinner />
            )}
      </Flex>
    </Card>
  )
}
export default ParametersForm
