
export const Background = ({ children }) => (
  <div style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    backgroundColor: '#f0f4f9'
  }}
  >
    <div style={{ ...overlay }} />
    {children}
  </div>
)

const overlay = {
  position: 'fixed',
  display: 'none',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 2,
  cursor: 'pointer'
}
