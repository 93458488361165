import React from 'react'
import { Flex } from '../../../Components/Common/Flex'
import { Text } from '../../../Components/Common/Text'
import { InputSwitch } from 'primereact/inputswitch'

const SwitchInput = ({ label, checked, onChange }) => (
  <Flex fw js row style={{ paddingTop: '.5em', gap: '.8em' }}>
    <Text title size={18} value={label} />
    <InputSwitch checked={checked} onChange={onChange} />
  </Flex>
)

export default SwitchInput
